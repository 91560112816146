import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.search.js";
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getisAdmin } from "@/api/index.js";
import { useStore } from "vuex";
import verifyWxLogin from "@utils/verifyWxLogin.js";
import utils from "@/utils/util.js";
import wxShare from "@utils/wxShare.js";
// 获取url参数

export default {
  __name: 'App',
  setup: function setup(__props) {
    var route = useRoute();
    var router = useRouter();
    var store = useStore();
    var getQueryVariable = function getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    };
    onMounted(function () {
      var userAgent = navigator.userAgent;
      if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
        if (!sessionStorage.getItem("originUrl")) {
          sessionStorage.setItem("originUrl", location.href); // 用于ios分享
        }
      }
      if (!localStorage.getItem("openId")) {
        verifyWxLogin.getCode();
      } else {
        getisAdmin().then(function (res) {
          if (res.code == 1) {
            store.commit("setRole", res.data.isAdmin);
            sessionStorage.setItem("role", res.data.isAdmin);
          }
        });
      }
      // wxShare.setShareInfo();
    });
    return function (_ctx, _cache) {
      var _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createBlock(_component_router_view);
    };
  }
};